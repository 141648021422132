
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Residences from "./pages/Residences";
import Neighborhoood from "./pages/Neighborhood";
import Availabilities from "./pages/Availabilities";
import GlobalLayout from "./layout";
import ErrorPage from "./pages/ErrorPage";
import Building from "./pages/Building";
import RedirectTrailingSlash from "./RedirectTrailingSlash"; 
import { createBrowserRouter } from "react-router-dom";


const routes = [
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/contact",
        element: <Contact />,
    },
    {
        path: "/residences",
        element: <Residences />,
    },
    {
        path: "/building",
        element: <Building />,
    },
    {
        path: "/neighborhood",
        element: <Neighborhoood />,
    },
    {
        path: "/availabilities",
        element: <Availabilities />,
    },
]

const router = createBrowserRouter([
    {
        path: "/",
        element: <GlobalLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                element: <RedirectTrailingSlash />, 
                children: routes,
            },
        ],
    },
]);


export default router;

