import { HashLink } from "react-router-hash-link";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
export default function TwoColumn({ data }) {

  const {
    heading,
    description,
    images,
    flexDirection,
    variant,
    padding,
    cta,
    hasPricing,
    prices,
    subHeading,
    type,
  } = data;

  return (
    <>
      <section
        className={variant + " two-column " + flexDirection}
        style={{
          padding: padding,
        }}
      >
        <div
          className="container"
          style={{
            display: "flex",
            flexDirection: flexDirection,
            justifyContent: "space-between",
            alignItems: "stretch",
          }}
        >
          <div className="content-image-wrap">
            {subHeading && <span className="sub-heading">{subHeading}</span>}
            <div className="content-wrap">
              <h2 className="heading">{heading}</h2>
              <p className="description">{description}</p>
              {cta && (
                <HashLink smooth className="cta"  aria-label={`Learn more about the ${subHeading}`} to={`${cta}#root`}>
                  Learn More
                </HashLink>
              )}

              {hasPricing && (
                <div className="prices">
                  Starting at ${prices}
                  <HashLink
                    smooth
                    aria-label="Learn more about availabilities"
                    to={`/availabilities?type=${type}#availabilitiesHeading`}
                  >
                    <span>View Availabilities</span>
                  </HashLink>
                </div>
              )}
            </div>
          </div>

          <Swiper
            modules={[Navigation, Pagination, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            pagination={{ clickable: false }}
          >
            {images.map((image, index) => (
              <SwiperSlide key={index} className="image-wrap">
                <img
                  src={image}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </>
  );
}
